import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const ListContent = styled.div`
  flex: 1 1 auto;
`;
